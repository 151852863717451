<template>
  <div class="home-page">
    <div class="image">
      <header
        class="my-header d-flex align-items-center"
        style="margin-top: -6px; "
      >
        <div class="container">
          <div class="my-header-content row">
            <div
              class="my-header-left-content col-sm-12 col-md-6 col-lg-6 d-inline-block"
            >
              <h1
                style="font-size:35px;font-family:Montserrat-extrabold"
                class="mb-0"
              >
                "{{ $t("OnlineAdm") }}"
              </h1>
              <p
                class="text-end"
                style="font-size:20px;font-family:Montserrat-extrabold;color:#2A3B5D"
              >
                {{ $t("Ш.Мирзиёев") }}
              </p>
              <p style="font-size:16px" class="col-sm-12 col-md-8">
                {{ $t("Process") }}
              </p>

              <div>
                <button class="my-button my-bg-danger" @click="OpenSidebar">
                  {{ $t("enter") }}
                </button>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
              <img src="/img/headerimg.png" alt="" />
            </div>
          </div>
        </div>
      </header>
    </div>
    <div class="w-100" style="background-color:#F7F9FF" id="news">
      <div class="container">
        <b-row class="py-3 align-items-center">
          <b-col sm="12" md="6">
            <span style="font-size: 24px;font-family:Montserrat-extrabold">{{
              $t("News")
            }}</span>
          </b-col>
          <b-col sm="12" md="6" class="text-end">
            <button
              class="my-button my-bg-primary"
              @click="$router.push({ name: 'News' })"
            >
              {{ $t("AllNews") }}
            </button>
          </b-col>
        </b-row>
        <b-row style="min-height:400px" class="pb-3">
          <b-col>
            <VueSlickCarousel v-bind="settings">
              <div
                class="w-100 p-2 h-100"
                style="height:400px !important"
                v-for="(item, index) in News"
                :key="index"
                @click="GetInfo(item)"
              >
                <div class="w-100 bg-danger h-100 position-relative">
                  <img
                    width="100%"
                    height="400px"
                    :src="
                      axios.defaults.baseURL +
                        `News/GetNewsImage/${item.imageId}`
                    "
                    alt=""
                  />
                  <div
                    class="w-100 position-absolute top-0 left-0 h-100 my-news-bg"
                  >
                    <div
                      class="d-flex align-items-end w-100 h-100 mb-5 text-white"
                    >
                      <div class="m-5 ">
                        <p class="news-text">
                          {{ item.title }}
                        </p>
                        <span> {{ item.date }} {{ $t("y") }} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div  class="w-100 p-2 h-100" style="height:400px !important">
                <div  class="w-100 bg-danger h-100 position-relative">
                  <img width="100%" height="400px" src="/img/headerimg.png" alt="">
                  <div  class="w-100 position-absolute top-0 left-0 h-100 my-news-bg">
                    <div class="d-flex align-items-end w-100 h-100 mb-5 text-white">
                      <div class="m-5 ">
                        <p class="news-text">
                          Бизнес-омбудсман тадбиркорнинг ер участкасини Олий суд орқали қайтарилишига эришди1
                        </p>
                        <span> 30.03.2023 й </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div  class="w-100 p-2 h-100" style="height:400px !important">
                <div  class="w-100 bg-danger h-100 position-relative">
                  <img width="100%" height="400px" src="/img/headerimg.png" alt="">
                  <div  class="w-100 position-absolute top-0 left-0 h-100 my-news-bg">
                    <div class="d-flex align-items-end w-100 h-100 mb-5 text-white">
                      <div class="m-5 ">
                        <p class="news-text">
                          Бизнес-омбудсман тадбиркорнинг ер участкасини Олий суд орқали қайтарилишига эришди
                        </p>
                        <span> 30.03.2024 й </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div  class="w-100 p-2 h-100" style="height:400px !important">
                <div  class="w-100 bg-danger h-100 position-relative">
                  <img width="100%" height="400px" src="/img/headerimg.png" alt="">
                  <div  class="w-100 position-absolute top-0 left-0 h-100 my-news-bg">
                    <div class="d-flex align-items-end w-100 h-100 mb-5 text-white">
                      <div class="m-5 ">
                        <p class="news-text">
                          Бизнес-омбудсман тадбиркорнинг ер участкасини Олий суд орқали қайтарилишига эришди
                        </p>
                        <span> 30.03.2025 й </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <template #prevArrow>
                <b-icon-chevron-left
                  scale="1.6"
                  class="text-primary"
                ></b-icon-chevron-left>
              </template>
              <template #nextArrow>
                <b-icon-chevron-right
                  scale="1.6"
                  class="text-primary"
                ></b-icon-chevron-right>
              </template>
              <!-- <template #prevArrow>
                1
              </template>
              <template #nextArrow>
                2
              </template> -->
            </VueSlickCarousel>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="w-100 pb-3" id="ControlFunction">
      <div class="container">
        <b-row class="py-3 align-items-center">
          <b-col sm="12" md="6">
            <span style="font-size: 24px;font-family:Montserrat-extrabold">{{
              $t("ControlFunction")
            }}</span>
          </b-col>
          <b-col sm="12" md="6" class="text-end"> </b-col>
        </b-row>
        <b-row class="mt-4 " style="margin-left:-6px">
          <b-col sm="12" md="6">
            <v-select
              :placeholder="$t('organization')"
              class="my-select"
              :options="OrganizationList"
              :reduce="(item) => item.value"
              label="text"
              v-model="ControlFuncFilter.parentOrganizationId"
              @input="ControlFuncRefresh"
            ></v-select>
          </b-col>
          <b-col sm="12" md="6">
            <button class="my-button my-bg-primary" @click="ControlFuncRefresh">
              <b-spinner v-if="ControlFuncLoading" small></b-spinner>
              {{ $t("refresh") }}
            </button>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col
            sm="12"
            class="my-2"
            v-for="(item, index) in ControlFuncList"
            :key="index"
          >
            <b-row class="my-card-border mx-2">
              <b-col sm="4" style="padding-left:40px">
                <p class="pt-3 my-text-primary">{{ $t("forms") }} :</p>
                <p>{{ item.forms.join(",") }}</p>
              </b-col>
              <b-col sm="4">
                <p class="pt-3 my-text-primary">{{ $t("normativdoc") }} :</p>
                <p>{{ item.normativeLegalDoc }}</p>
              </b-col>
              <b-col sm="4">
                <p class="pt-3 my-text-primary">{{ $t("function") }} :</p>
                <p>{{ item.name }}</p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mt-3" v-if="ControlFuncList.length > 0">
          <b-col class="d-flex justify-content-end">
            <b-pagination
              v-model="ControlFuncFilter.page"
              :total-rows="ControlFuncFilter.totalRows"
              :per-page="ControlFuncFilter.pageSize"
              first-number
              last-number
              @input="ControlFuncRefresh"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <b-icon icon="chevron-double-left" size="18" />
              </template>
              <template #next-text>
                <b-icon icon="chevron-double-right" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
    <div
      class="w-100 pb-4"
      style="background-color:#F7F9FF"
      id="MandRequirement"
    >
      <div class="container">
        <b-row class="py-3 align-items-center">
          <b-col sm="12" md="6">
            <span style="font-size: 24px;font-family:Montserrat-extrabold">{{
              $t("MandRequirement")
            }}</span>
          </b-col>
          <b-col sm="12" md="6" class="text-end"> </b-col>
        </b-row>
        <b-row class="mt-3 " style="margin-left:-6px">
          <b-col sm="12" md="6">
            <!-- <v-select
              :placeholder="$t('Oked')"
              class="my-select"
            ></v-select> -->
            <!-- <input type="text" class="my-input1"  :placeholder="$t('oked')" /> -->
            <v-select
              :placeholder="$t('organization')"
              class="my-select"
              :options="OrganizationList"
              :reduce="(item) => item.value"
              label="text"
              v-model="MandatoryRequirementFilter.parentOrganizationId"
              @input="MandatoryRequirementRefresh"
            ></v-select>
          </b-col>
          <b-col sm="12" md="6">
            <button
              class="my-button my-bg-primary"
              @click="MandatoryRequirementRefresh"
            >
              <b-spinner v-if="MandatoryRequirementLoading" small></b-spinner>
              {{ $t("refresh") }}
            </button>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col sm="12" class="my-2">
            <b-row class="my-card-border mx-2 pt-3 my-bg-primary-dark">
              <!-- <b-col sm="12" md="3" style="padding-left:40px"> 
                  <p style="font-family:Montserrat-bold"> {{ $t('Code') }} </p>
              </b-col> -->
              <!-- <b-col sm="12" md="4">  -->
              <b-col sm="12" md="5" style="padding-left:40px">
                <p style="font-family:Montserrat-bold">
                  {{ $t("normativdoc") }}
                </p>
              </b-col>
              <b-col sm="12" md="6">
                <p style="font-family:Montserrat-bold">
                  {{ $t("MandRequirement1") }}
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            sm="12"
            class="my-2"
            v-for="(item, index) in MandatoryRequirement"
            :key="index"
          >
            <b-row
              class="my-card-border mx-2 pt-3 bg-white d-flex align-items-center"
            >
              <!-- <b-col sm="12" md="3" style="padding-left:40px"> 
                  <p style="font-family:Montserrat-bold"> {{ item.orderCode }} </p>
              </b-col> -->
              <!-- <b-col sm="12" md="4">  -->
              <b-col sm="12" md="5" style="padding-left:40px">
                <p style="font-family:Montserrat-bold">
                  {{ item.normativeLegalDoc }}
                </p>
              </b-col>
              <b-col sm="12" md="6">
                <p style="font-family:Montserrat-bold">{{ item.name }}</p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mt-3" v-if="MandatoryRequirement.length > 0">
          <b-col class="d-flex justify-content-end">
            <b-pagination
              v-model="MandatoryRequirementFilter.page"
              :total-rows="MandatoryRequirementFilter.totalRows"
              :per-page="MandatoryRequirementFilter.pageSize"
              first-number
              last-number
              @input="MandatoryRequirementRefresh"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <b-icon icon="chevron-double-left" size="18" />
              </template>
              <template #next-text>
                <b-icon icon="chevron-double-right" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
    <!-- <div class="w-100 pb-4" id="Sorovnomalar">
      <div class="container">
        <b-row class="py-5 align-items-center">
          <b-col sm="12" md="6">
              <span style="font-size: 24px;font-family:Montserrat-extrabold">{{$t('Сўровномалар')}}</span>
          </b-col>
          <b-col sm="12" md="6" class="text-end">
            
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="4">
            <v-select
              :placeholder="$t('Oked')"
              class="my-select"
            ></v-select>
          </b-col>
          <b-col sm="12" md="4">
            <v-select
              :placeholder="$t('Oked')"
              class="my-select"
            ></v-select>
          </b-col>
          <b-col sm="12" md="4">
            <input type="text" class="my-input1"  :placeholder="$t('email')" />
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col>
            <textarea class="my-input1" name="" id="" cols="30" rows="10" :placeholder="$t('answer')">

            </textarea>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col>
            <button class="my-button my-bg-primary" >
              {{ $t("send") }}
            </button>
          </b-col>
        </b-row>
      </div>
    </div> -->
    <div class="w-100 pb-4">
      <div class="container">
        <b-row class="py-5 align-items-center">
          <b-col sm="12" md="6">
            <span style="font-size: 24px;font-family:Montserrat-extrabold">{{
              $t("Рақамлар")
            }}</span>
          </b-col>
          <b-col sm="12" md="6" class="text-end"> </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6" lg="3">
            <b-row>
              <b-col sm="12" md="12" class="d-flex align-items-center">
                <div
                  style="font-family:Montserrat-extrabold;font-size:50px;margin-right:20px"
                  class="mr-3"
                >
                  <number
                    class="bold transition"
                    style="font-family:Montserrat-extrabold;font-size:50px;margin-right:20px;color:#2A3B5D"
                    :class="{ scaleBig: scaleClass }"
                    ref="number2"
                    :from="0"
                    animationPaused
                    :to="PageData.allRequestCount"
                    :duration="5"
                    easing="Power4.easeOut"
                    @complete="completed"
                  />
                </div>
              </b-col>
              <!-- <b-col sm="12" md="5" class="d-flex align-items-center">
                <div>
                  {{ $t("agreedRequestCount") }}
                </div>
              </b-col> -->
            </b-row>
          </b-col>
          <b-col sm="12" md="6" lg="3">
            <b-row>
              <b-col sm="12" md="12" class="d-flex align-items-center">
                <div
                  style="font-family:Montserrat-extrabold;font-size:50px;margin-right:20px"
                  class="mr-3"
                >
                  <number
                    class="bold transition"
                    style="font-family:Montserrat-extrabold;font-size:50px;margin-right:20px;color:#2A3B5D"
                    :class="{ scaleBig: scaleClass }"
                    ref="number3"
                    :from="0"
                    animationPaused
                    :to="PageData.inspectionOrganiztionCount"
                    :duration="5"
                    easing="Power4.easeOut"
                    @complete="completed"
                  />
                </div>
              </b-col>
              <!-- <b-col sm="12" md="8" class="d-flex align-items-center">
                <div>
                  {{ $t("allRequestCount") }}
                </div>
              </b-col> -->
            </b-row>
          </b-col>
          <b-col sm="12" md="6" lg="3">
            <b-row>
              <b-col sm="12" md="12" class="d-flex align-items-center">
                <div
                  style="font-family:Montserrat-extrabold;font-size:50px;margin-right:20px"
                  class="mr-3"
                >
                  <number
                    class="bold transition"
                    style="font-family:Montserrat-extrabold;font-size:50px;margin-right:20px;color:#2A3B5D"
                    :class="{ scaleBig: scaleClass }"
                    ref="number4"
                    :from="0"
                    animationPaused
                    :to="PageData.agreedRequestCount"
                    :duration="5"
                    easing="Power4.easeOut"
                    @complete="completed"
                  />
                </div>
              </b-col>
              
            </b-row>
          </b-col>
          <b-col sm="12" md="6" lg="3">
            <b-row>
              <b-col sm="12" md="12" class="d-flex align-items-center">
                <div
                  style="font-family:Montserrat-extrabold;font-size:50px;margin-right:20px"
                  class="mr-3"
                >
                  <number
                    class="bold transition"
                    style="font-family:Montserrat-extrabold;font-size:50px;margin-right:20px;color:#2A3B5D"
                    :class="{ scaleBig: scaleClass }"
                    ref="number5"
                    :from="0"
                    animationPaused
                    :to="PageData.rejectedRequestCount"
                    :duration="5"
                    easing="Power4.easeOut"
                    @complete="completed"
                  />
                </div>
              </b-col>
             
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="8" lg="3">
            <b-row>
              <b-col sm="12" md="8" class="d-flex align-items-center">
                <div class="text-left">
                  {{ $t("agreedRequestCount") }}
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12" md="8" lg="3">
            <b-row>
              <b-col sm="12" md="8" class="d-flex align-items-center">
                <div class="text-left">
                  {{ $t("allRequestCount") }}
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12" md="8" lg="3">
            <b-row>
              <b-col sm="12" md="8" class="d-flex align-items-center">
                <div class="text-left">
                  {{ $t("inspectionOrganiztionCount") }}
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12" md="8" lg="3">
            <b-row>
              <b-col sm="12" md="8" class="d-flex align-items-center">
                <div class="text-left">
                  {{ $t("rejectedRequestCount") }}
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <!-- Test-->
          <b-col sm="12" md="6" lg="3" v-for="(item,index) in PageData.otherElements" :key="index">
            <b-row>
              <b-col sm="12" md="12" class="d-flex align-items-center">
                <div
                  style="font-family:Montserrat-extrabold;font-size:50px;margin-right:20px"
                  class="mr-3"
                >
                  <number
                    class="bold transition"
                    style="font-family:Montserrat-extrabold;font-size:50px;margin-right:20px;color:#2A3B5D"
                    :class="{ scaleBig: scaleClass }"
                    :ref="'number7'+index"
                    :from="0"
                    animationPaused
                    :to="item.value"
                    :duration="5"
                    easing="Power4.easeOut"
                    @complete="completed"
                  />
                  
                </div>
              </b-col>
            </b-row>
          </b-col>
          <!-- <b-col sm="12" md="6" lg="3">
            <b-row>
              <b-col sm="12" md="12" class="d-flex align-items-center">
                <div
                  style="font-family:Montserrat-extrabold;font-size:50px;margin-right:20px"
                  class="mr-3"
                >
                  <number
                    class="bold transition"
                    style="font-family:Montserrat-extrabold;font-size:50px;margin-right:20px;color:#2A3B5D"
                    :class="{ scaleBig: scaleClass }"
                    ref="number6"
                    :from="0"
                    animationPaused
                    :to="test2"
                    :duration="5"
                    easing="Power4.easeOut"
                    @complete="completed"
                  />
                </div>
              </b-col>
            </b-row>
          </b-col> -->
        </b-row>
        <b-row>
          <b-col sm="12" md="8" lg="3" v-for="(item,index) in PageData.otherElements" :key="index">
            <b-row>
              <b-col sm="12" md="8" class="d-flex align-items-center">
                <div class="text-left">
                  <!-- {{ $t("test1") }} -->
                  {{item.label}}
                </div>
              </b-col>
            </b-row>
          </b-col>
          <!-- <b-col sm="12" md="8" lg="3">
            <b-row>
              <b-col sm="12" md="8" class="d-flex align-items-center">
                <div class="text-left">
                  {{ $t("test2") }}
                </div>
              </b-col>
            </b-row>
          </b-col> -->
        </b-row>
      </div>
    </div>
    <div class="w-100" style="background-color:#F7F9FF" id="Videodarsliklar">
      <div class="container">
        <b-row class="pt-5 align-items-center">
          <b-col sm="12" md="6">
            <span style="font-size: 24px;font-family:Montserrat-extrabold">{{
              $t("Videodarsliklar")
            }}</span>
          </b-col>
          <b-col sm="12" md="6" class="text-end"> </b-col>
        </b-row>
        <b-row class="py-4">
          <b-col sm="12" md="6" lg="4">
            <b-row
              class="my-card-border m-2  pt-3 bg-white d-flex align-items-center"
              v-for="(item, index) in VideoCategoryList"
              @click="SetVideoCategory(item)"
              :style="{
                backgroundColor:
                  videocategory == item.value ? '#5965CE !important' : '',
                color: videocategory == item.value ? '#fff' : '',
              }"
              style="cursor:pointer"
              :key="index"
            >
              <b-col sm="12" style="padding-left:40px">
                <p style="font-family:Montserrat-bold">{{ item.text }}</p>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12" md="6" lg="8">
            <b-row>
              <b-col v-if="videolesson.length == 1" sm="12">
                <LazyYoutube :maxWidth="true" :src="videolesson[0].uri" />
              </b-col>
              <b-col
                sm="12"
                md="6"
                v-show="videolesson.length != 1 && videolesson.length != 0"
                v-for="(item, index) in videolesson"
                :key="index"
              >
                <LazyYoutube :maxWidth="true" :src="item.uri" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="w-100 text-white py-5" style="background-color:#1F2A51">
      <div class="container">
        <b-row>
          <b-col sm="12" class="mt-3">
            <div class="left-pos d-flex align-items-center">
              <div
                class="logo d-flex"
                style="cursor: pointer"
                @click="$router.push('/')"
              >
                <b-img src="/img/logodark.svg" width="50%" alt="" />
                <!-- <b-img style="margin-left:8px;margin-right:16px" src="/images/design/qabul.svg" width="80%" alt="" /> -->
              </div>
              <div class="orgname1 d-flex align-items-center">
                {{ $t("publiceducation") }}
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <b-row>
              <b-col sm="12" md="5" class="mt-3">
                <img src="/img/footer-app.png" alt="" />
              </b-col>
              <b-col sm="12" md="7" class="mt-3 d-flex align-items-center">
                <div>
                  <h2 style="font-family:Montserrat-extrabold;" class="mb-3">
                    {{ $t("mobileApp") }}
                  </h2>
                  <span> {{ $t("AllInfoInPhone") }} </span>
                  <b-row class="mt-3">
                    <b-col>
                      <a href="https://apps.apple.com/us/app/govcontrol-uz/id1630070272" target="_blank">
                        <img width="100%" src="https://click.uz/click/images/evo2.svg" alt="" />
                      </a>
                    </b-col>
                    <b-col class="pl-0">
                      <a href="https://play.google.com/store/apps/details?id=com.bo.govcontroluz" target="_blank">
                        <img width="100%"  src="https://click.uz/click/images/evo1.svg" alt="" />
                      </a>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12" md="6">
            <h3 style="font-family:Montserrat-bold;">
              {{ $t("connectwithus") }}
            </h3>
            <div class="d-flex my-1">
              <img style="margin-right:15px" src="/img/phone-icon.svg" alt="" />
              <span> (71) 202 08 97 </span>
            </div>
            <div class="d-flex my-1">
              <img style="margin-right:15px" src="/img/mail-icon.svg" alt="" />
              <span> info@biznesombudsman.uz </span>
            </div>
            <div class="d-flex my-1">
              <img style="margin-right:15px" src="/img/geo-icon.svg" alt="" />
              <span> {{ $t("addressus") }} </span>
            </div>
            <h3 style="font-family:Montserrat-bold;">{{ $t("links") }}</h3>
            <div class="d-flex my-1">
              <span>
                1. {{ $t("forinspector") }}:
                <a href="http://tt.govcontrol.uz" class="text-white">
                  http://tt.govcontrol.uz
                </a>
              </span>
            </div>
            <!-- <div class="d-flex my-1">
              <span>  2. {{ $t('forbo') }} : <a href="http://bo.govcontrol.uz" class="text-white"> http://bo.govcontrol.uz </a> </span>
            </div> -->
            <div class="d-flex my-1" style="cursor:pointer">
              <img
                style="margin-right:15px"
                src="/img/facebook-icon.svg"
                @click="GotoUrl('https://facebook.com/biznes.ombudsman')"
                alt=""
              />
              <img
                style="margin-right:15px"
                src="/img/telegram-icon.svg"
                @click="GotoUrl('https://t.me/biznes_ombudsman')"
                alt=""
              />
              <img
                style="margin-right:15px"
                src="/img/instagram-icon.svg"
                @click="GotoUrl('https://instagram.com/biznes_ombudsman')"
                alt=""
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <!-- 
    <div class="footer-container">
      <div class="row home-mobile-show ">
        <div class="col-md-8">
          <div class="d-flex justify-content-center">
            <div class="d-flex flex-wrap">
              <img src="images/design/mobile2.png" />
              <div class="d-flex flex-column justify-content-center">
                <h1 class="home-mobile-title">{{ $t("mobileapp") }}</h1>
                <div class="home-mobile-text my-3">
                  Сервис govcontrol.uz всегда под рукой
                </div>
                <div class="d-flex flex-wrap">
                  <img src="images/design/applestore.png" class="me-3" />
                  <a
                    href="https://play.google.com/store/apps/details?id=com.softnews.mymaktabuz"
                    ><img src="images/design/playmarket.png"
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div style="margin:32px">
            <h3>
              {{ $t("infoaboutportal") }}
            </h3>
            <p>
              {{ $t("aboutproject") }}
            </p>
            <p>
              {{ $t("usercontract") }}
            </p>
            <p>
              {{ $t("questionandanswer") }}
            </p>
          </div>
          <div style="margin:32px;margin-top:64px">
            <h2>
              {{ $t("contacts") }}
            </h2>
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.964"
                height="18"
                viewBox="0 0 17.964 18"
              >
                <path
                  id="Icon_feather-phone"
                  data-name="Icon feather-phone"
                  d="M20.076,15.476v2.71a1.806,1.806,0,0,1-1.969,1.806,17.875,17.875,0,0,1-7.795-2.773A17.613,17.613,0,0,1,4.892,11.8,17.875,17.875,0,0,1,2.119,3.969,1.806,1.806,0,0,1,3.917,2h2.71A1.806,1.806,0,0,1,8.433,3.554a11.6,11.6,0,0,0,.632,2.538A1.806,1.806,0,0,1,8.659,8L7.512,9.145a14.452,14.452,0,0,0,5.419,5.419l1.147-1.147a1.806,1.806,0,0,1,1.906-.406,11.6,11.6,0,0,0,2.538.632,1.806,1.806,0,0,1,1.554,1.834Z"
                  transform="translate(-2.112 -2)"
                  fill="#011422"
                  opacity="0.48"
                />
              </svg>
              <span style="margin-left:10px">
                +998 71 239-25-81 (09:00-18:00)
              </span>
            </p>
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  id="Path_477"
                  data-name="Path 477"
                  d="M10.4,3.069a10.617,10.617,0,0,1-3.743-.634A8.478,8.478,0,0,1,3.732.629a8.031,8.031,0,0,1-1.9-2.841,9.838,9.838,0,0,1-.675-3.719,9.457,9.457,0,0,1,.7-3.69,8.541,8.541,0,0,1,1.9-2.841,8.409,8.409,0,0,1,2.853-1.825,9.654,9.654,0,0,1,3.549-.644,11.143,11.143,0,0,1,3.712.586,8.178,8.178,0,0,1,2.843,1.659,7.311,7.311,0,0,1,1.81,2.557,8.313,8.313,0,0,1,.634,3.3,7.2,7.2,0,0,1-.327,2.284,4.716,4.716,0,0,1-.9,1.63,3.617,3.617,0,0,1-1.35.976,4.391,4.391,0,0,1-1.7.322,4.575,4.575,0,0,1-.859-.078,2.231,2.231,0,0,1-.747-.283,1.794,1.794,0,0,1-.573-.566,2.766,2.766,0,0,1-.358-.908,3.745,3.745,0,0,1-1.1,1.337,3.04,3.04,0,0,1-1.841.5A3.658,3.658,0,0,1,6.606-2.71,4.68,4.68,0,0,1,5.573-6.029v-.39A4.736,4.736,0,0,1,6.586-9.748a3.583,3.583,0,0,1,2.751-1.084,2.76,2.76,0,0,1,1.688.43,2.809,2.809,0,0,1,.828.957v-1.171H13.9v5.408a2.441,2.441,0,0,0,.266,1.357A.972.972,0,0,0,15-3.491a1.9,1.9,0,0,0,.808-.176,1.66,1.66,0,0,0,.655-.566,3.12,3.12,0,0,0,.44-1.035,6.43,6.43,0,0,0,.164-1.562,6.607,6.607,0,0,0-.563-2.811,5.753,5.753,0,0,0-1.514-2.011,6.421,6.421,0,0,0-2.2-1.2,8.587,8.587,0,0,0-2.639-.4,7.378,7.378,0,0,0-2.72.5,6.244,6.244,0,0,0-2.2,1.445,6.756,6.756,0,0,0-1.463,2.3,8.373,8.373,0,0,0-.532,3.075,8.578,8.578,0,0,0,.522,3.094A6.387,6.387,0,0,0,5.236-.543,6.42,6.42,0,0,0,7.506.892a8.137,8.137,0,0,0,2.894.5,10.572,10.572,0,0,0,2.731-.3A7.692,7.692,0,0,0,14.982.355V2.19a8.138,8.138,0,0,1-1.861.634A12.675,12.675,0,0,1,10.4,3.069Zm-.614-6.56a2.024,2.024,0,0,0,1.5-.566A2.092,2.092,0,0,0,11.853-5.6V-6.868A2.08,2.08,0,0,0,11.3-8.4a2.065,2.065,0,0,0-1.534-.556,1.731,1.731,0,0,0-1.4.6,3.01,3.01,0,0,0-.5,1.943v.39a2.966,2.966,0,0,0,.511,1.943A1.762,1.762,0,0,0,9.787-3.491Z"
                  transform="translate(-1.155 14.931)"
                  fill="#011422"
                  opacity="0.48"
                />
              </svg>
              <span style="margin-left:10px">
                info_ombudsman.uzb@umail.uz
              </span>
            </p>
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.857"
                height="18"
                viewBox="0 0 12.857 18"
              >
                <path
                  id="Path_478"
                  data-name="Path 478"
                  d="M6.429,0a6.429,6.429,0,0,1,6.429,6.429C12.857,9.979,6.429,18,6.429,18S0,9.979,0,6.429A6.429,6.429,0,0,1,6.429,0Z"
                  fill="#011422"
                  opacity="0.48"
                />
              </svg>
              <span style="margin-left:10px">
                {{ $t("xtvadress") }}
              </span>
            </p>
            <div style="margin-left : -5px">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="40"
                viewBox="0 0 41 40"
              >
                <g
                  id="Facebook_Button"
                  data-name="Facebook Button"
                  transform="translate(-19 -2460)"
                >
                  <rect
                    id="Rectangle_44"
                    data-name="Rectangle 44"
                    width="41"
                    height="40"
                    transform="translate(19 2460)"
                    fill="rgba(255,255,255,0)"
                  />
                  <path
                    id="Icon_metro-facebook"
                    data-name="Icon metro-facebook"
                    d="M29.238,1.928H7.9A5.333,5.333,0,0,0,2.571,7.262V28.594A5.333,5.333,0,0,0,7.9,33.928H18.571v-14h-4v-4h4v-3a5,5,0,0,1,5-5h5v4h-5a1,1,0,0,0-1,1v3h5.5l-1,4h-4.5v14h6.667a5.333,5.333,0,0,0,5.333-5.334V7.262a5.333,5.333,0,0,0-5.333-5.334Z"
                    transform="translate(21.429 2462.072)"
                    fill="#011422"
                    opacity="0.48"
                  />
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
              >
                <g
                  id="Instagram_Button"
                  data-name="Instagram Button"
                  transform="translate(-84 -2460)"
                >
                  <rect
                    id="Rectangle_45"
                    data-name="Rectangle 45"
                    width="40"
                    height="40"
                    transform="translate(84 2460)"
                    fill="rgba(255,255,255,0)"
                  />
                  <path
                    id="Icon_awesome-instagram"
                    data-name="Icon awesome-instagram"
                    d="M16,10.033a8.2,8.2,0,1,0,8.2,8.2A8.19,8.19,0,0,0,16,10.033Zm0,13.538a5.334,5.334,0,1,1,5.333-5.334A5.343,5.343,0,0,1,16,23.571ZM26.449,9.7a1.913,1.913,0,1,1-1.913-1.914A1.909,1.909,0,0,1,26.449,9.7Zm5.433,1.942a9.471,9.471,0,0,0-2.584-6.7,9.529,9.529,0,0,0-6.7-2.585c-2.641-.15-10.558-.15-13.2,0a9.515,9.515,0,0,0-6.7,2.578,9.5,9.5,0,0,0-2.584,6.7c-.15,2.642-.15,10.561,0,13.2a9.471,9.471,0,0,0,2.584,6.7,9.541,9.541,0,0,0,6.7,2.585c2.641.15,10.558.15,13.2,0a9.467,9.467,0,0,0,6.7-2.585,9.533,9.533,0,0,0,2.584-6.7c.15-2.642.15-10.553,0-13.2ZM28.47,27.67a5.4,5.4,0,0,1-3.041,3.042c-2.106.835-7.1.643-9.43.643s-7.332.186-9.43-.643A5.4,5.4,0,0,1,3.527,27.67c-.835-2.106-.642-7.1-.642-9.432S2.7,10.9,3.527,8.805A5.4,5.4,0,0,1,6.568,5.763c2.106-.835,7.1-.643,9.43-.643s7.332-.186,9.43.643A5.4,5.4,0,0,1,28.47,8.805c.835,2.106.642,7.1.642,9.432S29.3,25.571,28.47,27.67Z"
                    transform="translate(88.006 2461.762)"
                    fill="#00b894"
                  />
                </g>
              </svg>
              <a
                target="_blank"
                style="text-decoration:none"
                href="https://t.me/tekshiruvinfo_bot"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                >
                  <g
                    id="Telegram_Button"
                    data-name="Telegram Button"
                    transform="translate(-132 -2460)"
                  >
                    <rect
                      id="Rectangle_46"
                      data-name="Rectangle 46"
                      width="40"
                      height="40"
                      transform="translate(132 2460)"
                      fill="rgba(255,255,255,0)"
                    />
                    <path
                      id="Icon_awesome-telegram"
                      data-name="Icon awesome-telegram"
                      d="M16,.563a16,16,0,1,0,16,16A16,16,0,0,0,16,.563Zm7.858,10.961L21.232,23.9c-.194.877-.716,1.09-1.445.677l-4-2.948-1.929,1.858a1.01,1.01,0,0,1-.806.394l.284-4.071,7.413-6.7c.323-.284-.071-.445-.5-.161L11.09,18.717,7.142,17.485c-.858-.271-.877-.858.181-1.271l15.426-5.948c.716-.258,1.342.174,1.11,1.258Z"
                      transform="translate(136 2463.438)"
                      fill="#011422"
                      opacity="0.48"
                    />
                  </g>
                </svg>
                <span class="my-text-success">@tekshiruvinfo_bot </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <b-sidebar v-model="sidebar" no-header width="100%" right>
      <div class="w-100 h-100 bg-white">
        <div class="cl-stages-accepting-applications">
          <div class="row justify-content-between pb-5">
            <div class="news-date mt-5 mb-3 px-2">
              <b-icon icon="calendar2-date"></b-icon
              ><span style="margin-right:15px;margin-left:2px">{{
                NewsInfo.date
              }}</span>
              <b-icon icon="eye"></b-icon
              ><span style="margin-left:2px">{{ NewsInfo.viewCount }}</span>
            </div>
            <p
              class="news-description px-2 mb-1 text-center"
              style="font-size:22px;font-weight:bold"
            >
              {{ NewsInfo.title }}
            </p>
            <!-- <div class="a" v-html="NewsInfo.shortContent"></div> -->
            <div v-html="NewsInfo.content" class="mb-3 b"></div>
            <b-row>
              <b-col
                sm="12"
                lg="1"
                v-for="(item, index) in NewsInfo.tags"
                :key="index"
              >
                <a style="color:#0C2F75" href="">{{ item.name }}</a>
              </b-col>
            </b-row>
          </div>
        </div>
        <span
          class="close-btn"
          style="position: absolute; top: 0px; right: 0px; cursor: pointer"
          @click="sidebar = false"
        >
          <img src="images/design/fill-close.svg" alt="" />
        </span>
      </div>
    </b-sidebar>
    <div class="gototop" @click="topFunction">
      <b-icon icon="chevron-up"></b-icon>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
  BSidebar,
  BModal,
  BSpinner,
  BButton,
  BCarousel,
  BCarouselSlide,
  BIconX,
  BIconArrowLeft,
} from "bootstrap-vue";
import vClickOutside from "v-click-outside";
import customOutlineButton from "../components/elements/customButtonOutline.vue";
import customProgress from "../components/elements/customProgress.vue";
import customInput from "../components/elements/customInput.vue";
import customSelect from "../components/elements/customSelect.vue";
import customButton from "../components/elements/customButton.vue";
import AccountIcon from "../components/custom-icons/accountIcon.vue";
import admIcon from "../components/custom-icons/admIcon.vue";
import childIcon from "../components/custom-icons/childIcon.vue";
import NewsService from "@/services/news.service";
import VueSlickCarousel from "vue-slick-carousel";
import vSelect from "vue-select";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import { LazyYoutube, LazyVimeo } from "vue-lazytube";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import axios from "axios";
import ManualService from "@/services/manual.service";
import ControlFunctionService from "@/services/controlfunction.service";
import VideoCategoryService from "@/services/videocategory.service";
import VideoLessonService from "@/services/videolesson.service";
import MandatoryRequirementService from "@/services/mandatoryrequirement.service";
import LandingPageService from "../services/landingpage.service";
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  components: {
    BRow,
    BCol,
    BImg,
    BSidebar,
    customOutlineButton,
    customProgress,
    AccountIcon,
    admIcon,
    childIcon,
    customInput,
    customSelect,
    BModal,
    customButton,
    BSpinner,
    BButton,
    BCarousel,
    BCarouselSlide,
    VueSlickCarousel,
    BIconX,
    BIconArrowLeft,
    vSelect,
    LazyYoutube,
    LazyVimeo,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      test1: 176,
      test2: 5353,
      sidebar1: false,
      sidebar2: false,
      newsSidebar: false,
      instruction: false,
      StatisticList: [],
      tabIndex: 1,
      videoshow: false,
      allstatistic: false,
      OblastList: [],
      RegionList: [],
      MfyList: [],
      EducationLanguageList: [],
      StreetList: [],
      StreetHouseList: [],
      infoContent: "",
      hudud: false,
      FindSchoolloading: false,
      SearchByNameLoading: false,
      isOpenSchoolInput: false,
      SchoolList: [],
      slide: 0,
      sliding: false,
      News: [],
      NewsInfo: {},
      filter: {
        orderType: "",
        page: 1,
        pageSize: 3,
        search: "",
        sortBy: "",
      },
      axios,
      sidebar: false,
      settings: {
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        // responsive: [
        // 	{
        // 		breakpoint: 2200,
        // 		settings: {
        // 			arrows: false,
        // 			slidesToShow: 4
        // 		}
        // 	},
        // 	{
        // 		breakpoint: 1440,
        // 		settings: {
        // 			arrows: false,
        // 			slidesToShow: 3
        // 		}
        // 	},
        // 	{
        // 		breakpoint: 479,
        // 		settings: {
        // 			arrows: false,
        // 			slidesToShow: 1
        // 		}
        // 	}
        // ]
      },
      OrganizationList: [],
      ControlFuncLoading: false,
      ControlFuncList: [],
      ControlFuncFilter: {
        parentOrganizationId: 0,
        byOked: false,
        parentId: "",
        authorizedOnly: false,
        inspectionOnly: false,
        orderType: "asc",
        page: 1,
        pageSize: 2,
        sortBy: "",
        totalRows: 0,
      },
      ControlFuncFilters: {
        parentOrganizationId: {
          matchMode: "equals",
          value: 0,
          text: "",
        },
      },
      VideoCategoryList: [],
      videocategory: 0,
      videolesson: [],
      MandatoryRequirementLoading: false,
      MandatoryRequirementFilters: {
        parentOrganizationId: {
          matchMode: "equals",
          value: 0,
          text: "",
        },
      },
      MandatoryRequirementFilter: {
        byOked: false,
        level: 5,
        orderType: "asc",
        page: 1,
        pageSize: 4,
        sortBy: "",
        parentId: "",
        authorizedOnly: false,
        inspectionOnly: false,
        totalRows: 0,
      },
      MandatoryRequirement: [],
      PageData: {},
      scaleClass: false,
    };
  },
  created() {
    AOS.init();
    this.getNews();
    this.getOrganizationList();
    // this.ControlFuncRefresh()
    VideoCategoryService.GetAsSelectList().then((res) => {
      this.VideoCategoryList = res.data;
      this.videocategory = this.VideoCategoryList[0].value;
      this.GetVideoLesson(this.VideoCategoryList[0]);
    });
    // this.MandatoryRequirementRefresh()
    LandingPageService.GetPageData().then((res) => {
      this.PageData = res.data;
    });
    window.addEventListener("scroll", this.handleScroll);
    this.playAnimation()
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    lang() {
      return localStorage.getItem("locale") || "uz_cyrl";
    },
  },
  methods: {
    GotoUrl(url) {
      window.open(url, "_blank");
    },
    handleScroll(event) {
      if (document.documentElement.scrollTop < 450) {
      }
      if (
        document.documentElement.scrollTop >= 450 &&
        document.documentElement.scrollTop < 1000
      ) {
      }
      // if(document.documentElement.scrollTop >= 1000 && document.documentElement.scrollTop < 1800){
      // }
      // if(document.documentElement.scrollTop >= 1800 && document.documentElement.scrollTop < 2200){
      // }
      if (
        document.documentElement.scrollTop >= 1000 &&
        document.documentElement.scrollTop < 2400
      ) {
        this.playAnimation();
      }
      if (document.documentElement.scrollTop >= 2400) {
      }
    },
    completed() {
      this.scaleClass = true;
    },
    playAnimation() {
      this.scaleClass = false;
      this.$refs.number2.play();
      this.$refs.number3.play();
      this.$refs.number4.play();
      this.$refs.number5.play();
      this.$refs.number70[0].play();
      this.$refs.number71[0].play();
      this.$refs.number72[0].play();
      this.$refs.number73[0].play();
      this.$refs.number74[0].play();
      this.$refs.number75[0].play();
      this.$refs.number76[0].play();
      this.$refs.number77[0].play();
      this.$refs.number78[0].play();
      this.$refs.number79[0].play();
    },
    CounterFunc(number) {
      const speed = 200;
      number.forEach((counter) => {
        const animate = () => {
          const value = +counter.getAttribute("akhi");
          const data = +counter.innerText;

          const time = value / speed;
          if (data < value) {
            counter.innerText = Math.ceil(data + time);
            setTimeout(animate, 1);
          } else {
            counter.innerText = value;
          }
        };
        animate();
      });
    },
    MandatoryRequirementRefresh() {
      this.MandatoryRequirementLoading = true;
      if (!!this.MandatoryRequirementFilters.parentOrganizationId.value) {
        this.MandatoryRequirementFilter.filters = this.MandatoryRequirementFilters;
      }
      MandatoryRequirementService.GetListLandingPage(
        this.MandatoryRequirementFilter
      )
        .then((res) => {
          this.MandatoryRequirement = res.data.rows;
          this.MandatoryRequirementLoading = false;
          this.MandatoryRequirementFilter.totalRows = res.data.total;
        })
        .catch((error) => {
          this.MandatoryRequirementLoading = false;
          this.makeToast(error.response.data.errors, "error");
        });
    },
    SetVideoCategory(item) {
      this.videocategory = item.value;
      this.GetVideoLesson(item);
    },
    GetVideoLesson(item) {
      VideoLessonService.GetList({
        filters: {
          categoryId: {
            value: item.value,
            matchMode: "equals",
          },
        },
        search: "",
        sortBy: "",
        orderType: "asc",
        page: 1,
        pageSize: 100,
      })
        .then((res) => {
          this.videolesson = res.data.rows;
          this.filter.totalRows = res.data.total;
        })
        .catch((error) => {
          this.Loading = false;
          this.makeToast(error.response.data, "error");
        });
    },
    ControlFuncRefresh() {
      this.ControlFuncLoading = true;
      if (!!this.ControlFuncFilters.parentOrganizationId.value) {
        this.ControlFuncFilter.filters = this.ControlFuncFilters;
      }
      ControlFunctionService.GetListLandingPage(this.ControlFuncFilter)
        .then((res) => {
          this.ControlFuncList = res.data.rows;
          this.ControlFuncFilter.totalRows = res.data.total;
          this.ControlFuncLoading = false;
        })
        .catch((error) => {
          this.ControlFuncLoading = false;
          this.makeToast(error.response.data.errors, "error");
        });
    },
    getOrganizationList() {
      ManualService.GetOrganizationAsSelectList("", false, false).then(
        (res) => {
          this.OrganizationList = res.data;
        }
      );
    },
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    // bindListener() {
    //     document.getElementById('btn').addEventListener('click', this.handler);
    // },
    // unbindListener() {
    // document.getElementById('btn').removeEventListener('click', this.handler);
    // },
    // handler() {
    // alert('Whoo-Ha!');
    // },
    bindlis() {
      document
        .getElementById("gotoschool")
        .addEventListener("click", this.GotoSchoolInfo);
    },
    QuotaWidth(item) {
      if (item.Quota > item.ApplicantCount) {
        return "100%";
      }
      if (item.Quota < item.ApplicantCount && item.Quota != 0) {
        return (item.Quota * 100) / item.ApplicantCount + "%";
      }
      if (item.Quota == 0) {
        return "0%";
      }
    },
    ApplicationWidth(item) {
      if (item.Quota < item.ApplicantCount) {
        return "100%";
      }
      if (item.Quota > item.ApplicantCount && item.ApplicantCount != 0) {
        return (item.ApplicantCount * 100) / item.Quota + "%";
      }
      if (item.ApplicantCount == 0) {
        return "0%";
      }
    },
    closeschoolinfo() {
      this.isOpenSchoolInput = false;
    },
    FindByName(item) {
      this.isOpenSchoolInput = false;
      this.getLocationSchool(item);
      this.map.center = {
        lat: this.map.data.latitude,
        lng: this.map.data.longitude,
      };
    },
    InfoBoxSave() {
      localStorage.setItem("infobox", 1);
      this.$store.state.infobox = false;
    },
    CloseAllStatistic() {
      this.allstatistic = true;
    },
    ChangeOblast() {
      this.filter.regionid = null;
      this.filter.mfyid = null;
      this.filter.streetid = null;
      this.filter.streethouseid = null;
      this.filter.streethousename = "";
      this.getRegionList();
    },
    Register() {
      this.$store.state.isOpenSidebar = !this.$store.state.isOpenSidebar;
      localStorage.removeItem("admtype");
      this.$store.state.admtype = 0;
    },
    CloseInstruction() {
      this.instruction = false;
      this.$refs.videoRef.pause();
    },
    VideoShow() {
      if (this.videoshow) {
        this.videoshow = false;
        this.$refs.videoRef.pause();
      }
      if (!this.videoshow) {
        this.videoshow = true;
        this.$refs.videoRef.play();
      }
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    OpenSidebar() {
      this.$store.state.isOpenSidebar = true;
    },
    getNews() {
      NewsService.GetList(this.filter)
        .then((res) => {
          this.News = res.data.rows;
        })
        .catch((error) => {
          this.Loading = false;
          this.$message(error.response.data);
        });
    },
    GetInfo(item) {
      this.sidebar = true;
      NewsService.Get(item.id).then((res) => {
        this.NewsInfo = res.data;
      });
    },
    makeToast(message, type) {
      var a = "";
      if (message.status == 500) {
        a = message.title;
      }
      if (message.status == 400) {
        var errors = Object.values(message.errors);
        var a = errors.map((el, item) => item + 1 + "." + el).join("\n");
      } else {
        a = message;
      }
      this.$toast.open({
        message: a,
        type: type,
        duration: 5000,
        dismissible: true,
      });
    },
  },
};
</script>

<style>
.gototop {
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.orgname1 {
  font-family: Montserrat-extrabold;
  width: 230px;
  font-size: 20px;
  margin-left: 20px;
  text-transform: uppercase;
}
.my-input1 {
  width: 100%;
  padding: 13px 20px;
  border: none;
  outline: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #fff;
}
.my-bg-primary-dark {
  background-color: #5965ce;
  color: #fff;
}

.my-select .vs__dropdown-toggle {
  padding: 12px 10px !important;
  border: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #fff;
}
.my-news-bg {
  background: rgb(15, 46, 202);
  background: linear-gradient(
    90deg,
    rgba(15, 46, 202, 1) 0%,
    rgba(15, 46, 202, 0) 100%
  );
}
.image {
  /* background-image: url("login_image.jpg"); */
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-color: rgba(22, 17, 17, 0.152);
   width : 100%;
   height: 100%; */
}
.cardStyle {
  height: 500px;
  cursor: pointer;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.news-text {
  font-family: Montserrat-bold;
  font-size: 20px;
}
.news-text > *,
.news-text p strong {
  font-family: Montserrat-bold;
  font-size: 20px;
}
.news-text:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
